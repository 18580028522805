'use client'

import { Button } from "@nextui-org/button";
import { useTranslations } from "next-intl";

export default function IframeButton() {
    const t = useTranslations('Home')
  return (
    <div className="mt-4 flex space-x-4">
      <Button 
        onClick={() => {
          const iframe = document.querySelector('iframe');
          if (iframe instanceof HTMLIFrameElement) {
            if (iframe.requestFullscreen) {
              iframe.requestFullscreen();
            } else if ('mozRequestFullScreen' in iframe) { // Firefox
              (iframe as any).mozRequestFullScreen();
            } else if ('webkitRequestFullscreen' in iframe) { // Chrome, Safari and Opera
              (iframe as any).webkitRequestFullscreen();
            } else if ('msRequestFullscreen' in iframe) { // IE/Edge
              (iframe as any).msRequestFullscreen();
            }
          }
        }}
        className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-6 rounded-full shadow-md transition duration-300 ease-in-out hover:shadow-lg"
      >
        {t('fullscreenButton')}
      </Button>
      <Button 
        onClick={() => {
          const url = window.location.href;
          navigator.clipboard.writeText(url).then(() => {
            alert(t('urlCopied'));
          });
        }}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full shadow-md transition duration-300 ease-in-out hover:shadow-lg"
      >
        {t('shareButton')}
      </Button>
    </div>
  )
}
